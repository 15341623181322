import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faTruck, faSmileBeam } from "@fortawesome/pro-solid-svg-icons"

import FancyNumber from "../../components/fancyNumber";
import { inject } from "mobx-react";
import { PAGE_VIEW_EVENT } from "../../utils/constants";
import { VisitorStore, OrderStore, ShopStore, OrderItem } from "stores";
import SEO from "../../components/seo";
import { getOrderTotals } from "../../utils/totals";


@inject("visitorStore", "orderStore", "shopStore")
export default class OrderCompletedPage extends React.Component<any> {
  componentWillMount() {
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "shop-orderCompleted"
    });
  }
  componentDidMount() {
    // Reload the trustbadge.
    if (typeof(window) !== "undefined") {
      const trustbadge: any = window.trustbadge;
      if (trustbadge) {
        trustbadge.reInitialize();
      }
    }
  }
  render() {
    const orderStore = this.props.orderStore as OrderStore;
    const shopStore = this.props.shopStore as ShopStore;
    const { total } = getOrderTotals(shopStore, orderStore);

    const data = this.props.data.me;
    const headerText = data.txtHeader[process.env.GATSBY_LANGUAGE].html;
    const header = data.image ? data.image.childImageSharp.fluid : undefined;
    return (
      <>
        <SEO subTitle={data.title[process.env.GATSBY_LANGUAGE]} />
        <section id="header" className="pa2 flex relative flex-column">
          {header ? <Img fluid={header} className="h5" /> : undefined}
          <div className="mt2 lh-copy f5" dangerouslySetInnerHTML={{ __html: headerText }} />
        </section>
        <section id="orderoverview" className="pa2">
          <h3 className="mt0">{data.txtOrderOverviewHeader[process.env.GATSBY_LANGUAGE]}</h3>
          <p>{data.txtOrderId[process.env.GATSBY_LANGUAGE]}: {orderStore.current.id}</p>
          {this.renderCart()}
          {this.renderTotals()}
          {this.renderCustomerDetails()}
          {/* {this.renderSteps()} */}
        </section>
        <div id="trustedShopsCheckout" style={{ display: "none" }}>
          <span id="tsCheckoutOrderNr">{orderStore.current.id}</span>
          <span id="tsCheckoutBuyerEmail">{orderStore.current.customer.email}</span>
          <span id="tsCheckoutOrderAmount">{total}</span>
          <span id="tsCheckoutOrderCurrency">EUR</span>
          <span id="tsCheckoutOrderPaymentType">{orderStore.current.paymentMethod}</span>
          <span id="tsCheckoutOrderEstDeliveryDate">3-5 Werktagen</span>
        </div>
      </>
    )
  }
  renderCart = () => {
    const store: OrderStore = this.props.orderStore;
    const texts = this.props.data.squidexTexts;
    return (
      <section id="cart">
        <div className="dt ph2 ph0-ns mt2 w-100">
          <div className="dt-row w-100">
            <div className="pv2 dtc b w3 bb b--black-10">{/*texts.txtImage[process.env.GATSBY_LANGUAGE]*/}</div>
            <div className="pv2 dtc b pl3 bb b--black-10">{texts.txtProduct[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dtc b pl3 w3 bb b--black-10">{texts.txtAmount[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dn dtc-ns w4 b tr pl3 bb b--black-10">{texts.txtPricePerPiece[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dn dtc-ns w4 b tr pl3 bb b--black-10">{texts.txtSubtotal[process.env.GATSBY_LANGUAGE]}</div>
          </div>
        </div>
        {
          store.current.items.map(this.renderItem)
        }
      </section>
    )
  }
  renderItem = (i: OrderItem, index: number) => {
    const texts = this.props.data.squidexTexts;
    return (
      <div id={`item-${index}`} key={`item-${index}`}>
        <div className="dt ph2 ph0-ns mt2 w-100">
          <div className="dt-row w-100">
            <div className="dtc v-mid w3">
              <img className="br3 ba b--black-10 h3 w3" src={i.thumb} />
            </div>
            <div className="dtc pl3">
              <p className="b">{i.material.name}</p>
              <p>{texts.txtFormat[process.env.GATSBY_LANGUAGE]}: {i.absWidth}x{i.absHeight}</p>
              {
                i.options.map((o, index) => (
                  <p key={`item-${index}-opt-${o.id}`}>- {o.name}</p>
                ))
              }
            </div>
            <div className="dtc v-mid w3 pl3">
              <div className="dib w-100 tc relative">
                {i.amount}
              </div>
            </div>
            <div className="dn dtc-ns v-mid w4 tr pl3">
              &euro; {i.price.toFixed(2)}
            </div>
            <div className="dn dtc-ns v-mid tr w4 pl3">
              &euro; {(i.price * i.amount).toFixed(2)}
            </div>
          </div>
        </div >
      </div>
    )
  }
  renderTotals = () => {
    const orderStore: OrderStore = this.props.orderStore;
    const { subTotal, discount, deliverCost, giftAmount, total } = getOrderTotals(
      this.props.shopStore,
      orderStore
    );
    const texts = this.props.data.squidexTexts;
    return (
      <section id="totals" className="cf">
        <div className="fr-ns w-100 w-third-ns pa2 pa0-ns">
          <div className="dib w-60">
            <p className="tl b mb2">{texts.txtSubtotal[process.env.GATSBY_LANGUAGE]}</p>
            {discount > 0 ? <p className="tl b mb2">{texts.txtDiscount[process.env.GATSBY_LANGUAGE]}</p> : undefined}
            {giftAmount > 0 ? <p className="tl b mb2">{texts.txtGiftcard[process.env.GATSBY_LANGUAGE]}</p> : undefined}
            <p className="tl b mb2">{texts.txtDeliverCosts[process.env.GATSBY_LANGUAGE]}</p>
            <div className="mt1 mb1 w-100 bb b--black-20"></div>
            <p className="tl b mb2">{texts.txtTotal[process.env.GATSBY_LANGUAGE]}</p>
            <p className="tl b">{texts.txtPaymentMethod[process.env.GATSBY_LANGUAGE]}</p>
          </div>
          <div className="dib w-40 tr">
            <p className="mb2">&euro; {subTotal.toFixed(2)}</p>
            {discount > 0 ? <p className="mb2">&euro; -{discount.toFixed(2)}</p> : undefined}
            {giftAmount > 0 ? <p className="mb2">&euro; -{giftAmount.toFixed(2)}</p> : undefined}
            <p className="mb2">&euro; {deliverCost.toFixed(2)}</p>
            <div className="mt1 mb1 w-100 bb b--black-20"></div>
            <p className="mb2">&euro; {total.toFixed(2)}</p>
            <p>{orderStore.current.paymentMethod}</p>
          </div>
        </div>
      </section>
    )
  }
  renderCustomerDetails = () => {
    const store: OrderStore = this.props.orderStore;
    const customer = store.current.customer;
    const texts = this.props.data.squidexTexts;
    return (
      <section id="customerdetails" className="cf mt2">
        <div className="fl w-100 w-40-ns">
          <div className="mw6 mt2-ns center mr0-l ml0-l b--black-10 ba-ns">
            <h3 className="f4 bg-theme white br--top-ns mv0 pv2 ph3 tc">{texts.txtCustomerDeliveryAddress[process.env.GATSBY_LANGUAGE]}</h3>
            <div className="pa3 bt b--black-10 mw6">
              <p className="mt0">{customer.firstName} {customer.lastName}</p>
              <p>{customer.street}</p>
              <p>{customer.postalCode} {customer.place}</p>
              <p>{customer.country}</p>
              <p className="mb0">{customer.email}</p>
            </div>
          </div>
        </div>
        <div className="fr w-100 w-40-ns mw6 mt2-ns center mr0-l ml0-l b--black-10 ba-ns">
          <h3 className="f4 bg-theme white br--top-ns mv0 pv2 ph3 tc">{texts.txtCustomerInvoiceAddress[process.env.GATSBY_LANGUAGE]}</h3>
          <div className="pa3 bt b--black-10 mw6">
            <p className="mt0">{customer.invoiceFirstName} {customer.invoiceLastName}</p>
            <p>{customer.invoiceStreet}</p>
            <p>{customer.invoicePostalCode} {customer.invoicePlace}</p>
            <p className="mb0">{customer.invoiceCountry}</p>
          </div>
        </div>
      </section>
    )
  }
  renderSteps = () => {
    const data = this.props.data.me;
    return (
      <>
        <h3 className="pa2">{data.txtStepsHeader[process.env.GATSBY_LANGUAGE]}</h3>
        <div className="pa2 flex flex-row">
          <div className="w-third flex-column items-center">
            <FancyNumber className="dib" number={1} />
            <h5 className="dib ml3 f4 ma0">{data.txtStepPrintHeader[process.env.GATSBY_LANGUAGE]}</h5>
          </div>
          <div className="w-third flex-column items-center">
            <FancyNumber className="dib" number={2} />
            <h5 className="dib ml3 f4 ma0">{data.txtStepDeliverHeader[process.env.GATSBY_LANGUAGE]}</h5>
          </div>
          <div className="w-third flex-column items-center">
            <FancyNumber className="dib" number={3} />
            <h5 className="dib ml3 f4 ma0">{data.txtStepEnjoyHeader[process.env.GATSBY_LANGUAGE]}</h5>
          </div>
        </div>
        <div className="pa2 flex flex-row mb4">
          <div className="w-third flex items-center">
            <div className="w-30">
              <FontAwesomeIcon icon={faPrint} className="f1 near-black" />
            </div>
            <div className="w-60">
              {data.txtStepPrint[process.env.GATSBY_LANGUAGE]}
            </div>
          </div>
          <div className="w-third flex items-center">
            <div className="w-30">
              <FontAwesomeIcon icon={faTruck} className="f1 near-black" />
            </div>
            <div className="w-60">
              {data.txtStepDeliver[process.env.GATSBY_LANGUAGE]}
            </div>
          </div>
          <div className="w-third flex items-center">
            <div className="w-30">
              <FontAwesomeIcon icon={faSmileBeam} className="f1 near-black" />
            </div>
            <div className="w-60">
              {data.txtStepEnjoy[process.env.GATSBY_LANGUAGE]}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const query = graphql`
{
  me: squidexOrderCompletedPage(identity: {eq: "orderCompleted"}) {
    txtHeader {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
    txtOrderOverviewHeader {
      de
      nl
      en
    }
    txtOrderId {
      de
      nl
      en
    }
    title {
      de
      nl
      en
    }
    image {
      childImageSharp {
        fluid(maxHeight: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    txtStepsHeader {
      de
      nl
      en
    }
    txtStepPrintHeader {
      de
      nl
      en
    }
    txtStepDeliverHeader {
      de
      nl
      en
    }
    txtStepEnjoyHeader {
      de
      nl
      en
    }
    txtStepPrint {
          de
      nl
        en
      }
    txtStepDeliver {
      de
      nl
      en
    }
    txtStepEnjoy {
      de
      nl
      en
    }
  }
  squidexTexts(identity: {eq: "squidextexts"}) {
    txtPaymentMethod {
      de
      nl
      en
    }
    pageShopOrder {
      de
      nl
      en
    }
    txtImage {
      de
      nl
      en
    }
    txtProduct {
      de
      nl
      en
    }
    txtFormat {
      de
      nl
      en
    }
    txtAmount {
      de
      nl
      en
    }
    txtPricePerPiece {
      de
      nl
      en
    }
    txtSubtotal {
      de
      nl
      en
    }
    txtTotal {
      de
      nl
      en
    }
    txtDiscount {
      de
      nl
      en
    }
    txtGiftcard {
      de
      nl
      en
    }
    txtDeliverCosts {
      de
      nl
      en
    }
    txtCustomerDeliveryAddress{
      de
      nl
      en
    }
    txtCustomerInvoiceAddress {
      de
      nl
      en
    }
    txtMultisafepayRedirect {
      de
      nl
      en
    }
    txtAgreeToPurchase {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
    btnFinalizeOrder {
      de
      nl
      en
    }
    txtGenericErrorHeader {
      de
      nl
      en
    }
    txtGenericErrorBody {
      de
      nl
      en
    }
    txtValidationErrorHeader {
      de
      nl
      en
    }
    txtNoAgreeToPurchaseError {
      de
      nl
      en
    }
  }
}`
